import React from "react";
import Home from "../components/Dashboard/Home";
const Dashboard = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default Dashboard;
