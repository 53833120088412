import React, { useState, useEffect } from "react";
import s from "./subscription.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid, Table, TableBody, TableCell, TableHead } from "@mui/material";
import { addTestimonial_api, updateTestimonial_api } from "../api/review";
import { addevent_api, updateevent_api } from "../api/event";
import { addHoliday_api } from "../api/hoilday";
import { addSubscription_api, updateSubscription_api } from "../api/subscription";
import { MdDelete, MdOutlineAddCard } from "react-icons/md";
import { useForm } from "react-hook-form";
const AddSubscription = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState("");
    const [hindiName, setHindiName] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [hindiSubTitle, setHindiSubTitle] = useState("");
    const [thumbnailImage, setThumbnailImage] = useState();
    const [images, setImages] = useState([]);
    const [place, setPlace] = useState("");
    const [hindiPlace, setHindiPlace] = useState("");
    const [about, setAbout] = useState("");
    const [hindiAbout, setHindiAbout] = useState("");
    // const [benefitHeading, setBenefitHeading] = useState("")
    // const [benefitValue, setBenefitValue] = useState("")
    const [plans, setPlans] = useState([{ duration: "", price: "" }])
    const [benefits, setBenefits] = useState([{ heading: "", value: "" }])
    const [hindiBenefits, setHindiBenefits] = useState([{ heading: "", value: "" }])
    const [faqs, setFaqs] = useState([{ heading: "", value: "" }])
    const [hindiFaqs, setHindiFaqs] = useState([{ heading: "", value: "" }])



    console.log(plans, "plans==========================")

    const pagetype = location.state.pagetype;

    useEffect(() => {
        if (pagetype === "Edit") {
            setName(location?.state?.data?.name);
            setHindiName(location?.state?.data?.hindiName);
            setPlace(location?.state?.data?.place);
            setHindiPlace(location?.state?.data?.HindiPlace);
            setSubTitle(location?.state?.data?.subTitle);
            setHindiSubTitle(location?.state?.data?.HindiSubTitle);
            setAbout(location?.state?.data?.about);
            setHindiAbout(location?.state?.data?.hindiAbout)
            setPlans(location?.state?.data?.plainItems);
            if ((location?.state?.data?.benefits).length !== 0) {
                setBenefits(location?.state?.data?.benefits);
            } else {
                setBenefits([{ heading: "", value: "" }])
            }
            if ((location?.state?.data?.hindiBenefits).length !== 0) {
                setHindiBenefits(location?.state?.data?.hindiBenefits);
            } else {
                setHindiBenefits([{ heading: "", value: "" }])
            }
            if ((location?.state?.data?.faq).length !== 0) {
                setFaqs(location?.state?.data?.faq);
            } else {
                setFaqs([{ heading: "", value: "" }]);
            }
            if ((location?.state?.data?.hindiFaq).length !== 0) {
                setHindiFaqs(location?.state?.data?.hindiFaq);
            } else {
                setHindiFaqs([{ heading: "", value: "" }]);
            }
        }
    }, [location]);

    // const {
    //     register,
    //     handleSubmit,
    //     formState: { errors },
    //     reset,
    // } = useForm();
    // console.log(handleSubmit, "handleSubmit-------------------------------")
    //     Plans Data
    const addPlansRecord = () => {
        setPlans([...plans, { duration: "", price: "" }]);
    };
    const deletePlansRow = (index) => {
        console.log(index)
        if (index == 0) {
            setPlans(plans)
        } else {
            let newArray = plans.filter((data, key) => key !== index);
            setPlans(newArray);
        }
    };

    const handlePlansChange = (index, event) => {
        const { name, value } = event.target;
        const updatedData = [...plans];

        updatedData[index][name] = value;
        setPlans(updatedData);
    };

    //     English Benefits

    const addBenefitRecord = () => {
        setBenefits([...benefits, { heading: "", value: "" }]);
    };
    const deleteBenefitRow = (index) => {
        console.log(index)
        if (index == 0) {
            setBenefits(benefits)
        } else {
            let newArray = benefits.filter((data, key) => key !== index);
            setBenefits(newArray);
        }
    };

    //  Hindi Benefits
    const addHindiBenefitRecord = () => {
        setHindiBenefits([...hindiBenefits, { heading: "", value: "" }]);
    };
    const deleteHindiBenefitRow = (index) => {

        console.log(index)
        if (index == 0) {
            setHindiBenefits(hindiBenefits)
        } else {
            let newArray = hindiBenefits.filter((data, key) => key !== index);
            setHindiBenefits(newArray);
        }
    };
    // English FAQs
    const addFaqsRecord = () => {
        console.log("addd faqssssssssssssssssss")
        setFaqs([...faqs, { heading: "", value: "" }]);
    };
    const deleteFaqsRow = (index) => {
        console.log(index)
        if (index == 0) {
            setFaqs(faqs)
        } else {
            let newArray = faqs.filter((data, key) => key !== index);
            setFaqs(newArray);
        }
    };

    // Hindi Faqs

    const addHindiFaqsRecord = () => {
        setHindiFaqs([...hindiFaqs, { heading: "", value: "" }]);
    };
    const deleteHindiFaqsRow = (index) => {

        console.log(index)
        if (index == 0) {
            setHindiFaqs(hindiFaqs)
        } else {
            let newArray = hindiFaqs.filter((data, key) => key !== index);
            setHindiFaqs(newArray);
        }
    };


    const handleBenefitChange = (index, event) => {
        const { name, value } = event.target;
        const updatedData = [...benefits];
        updatedData[index][name] = value;
        setBenefits(updatedData);
    };


    const handleHindiBenefitChange = (index, event) => {
        const { name, value } = event.target;
        const updatedData = [...hindiBenefits];
        updatedData[index][name] = value;
        setHindiBenefits(updatedData);
    };

    const handleFaqsChange = (index, event) => {
        const { name, value } = event.target;
        const updatedData = [...faqs];
        updatedData[index][name] = value;
        setFaqs(updatedData);
    };

    const handleHindiFaqsChange = (index, event) => {
        const { name, value } = event.target;
        const updatedData = [...hindiFaqs];
        updatedData[index][name] = value;
        setHindiFaqs(updatedData);
    };



    const addSubscriptionFunc = async () => {
        if (pagetype == "Add") {
            const fd = new FormData();
            fd.append("name", name)
            fd.append("hindiName", hindiName)
            fd.append("place", place)
            fd.append("HindiPlace", hindiPlace)
            fd.append("about", about)
            fd.append("hindiAbout", hindiAbout)
            fd.append("subTitle", subTitle)
            fd.append("HindiSubTitle", hindiSubTitle)
            fd.append("thumbImage", thumbnailImage)
            // Serialize arrays of objects to JSON strings
            fd.append("plainItems", JSON.stringify(plans));
            fd.append("benefits", JSON.stringify(benefits));
            fd.append("hindiBenefits", JSON.stringify(hindiBenefits));
            fd.append("faq", JSON.stringify(faqs));
            fd.append("hindiFaq", JSON.stringify(hindiFaqs));
            images.forEach((imageObj) => {
                fd.append('images', imageObj.file);
            });
            // fd.append("images", JSON.stringify(images))
            // fd.append("plainItems", plans)
            // fd.append("benefits", benefits)
            // fd.append("hindiBenefits", hindiBenefits)
            // fd.append("faq", faqs)
            // fd.append("hindiFaq", hindiFaqs)
            try {
                let res = await addSubscription_api(fd);
                if (res.data.status) {
                    navigate("/subscription-list");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
        if (pagetype == "Edit") {
            const fd = new FormData();
            fd.append("name", name)
            fd.append("hindiName", hindiName)
            fd.append("place", place)
            fd.append("HindiPlace", hindiPlace)
            fd.append("about", about)
            fd.append("hindiAbout", hindiAbout)
            fd.append("subTitle", subTitle)
            fd.append("HindiSubTitle", hindiSubTitle)
            if (thumbnailImage) {
                fd.append("thumbImage", thumbnailImage)
            }
            // fd.append("images", images)
            images.forEach((imageObj) => {
                fd.append('images', imageObj.file);
            });
            fd.append("plainItems", JSON.stringify(plans));
            fd.append("benefits", JSON.stringify(benefits));
            fd.append("hindiBenefits", JSON.stringify(hindiBenefits));
            fd.append("faq", JSON.stringify(faqs));
            fd.append("hindiFaq", JSON.stringify(hindiFaqs));
            fd.append("id", location?.state?.data?._id);
            try {
                let res = await updateSubscription_api(fd);
                if (res.data.status) {
                    console.log(res);
                    navigate("/subscription-list");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
    };


    const handleHoliday = (type, data) => {
        setHoliday(prevHoliday => ({ ...prevHoliday, [type]: data }));
    }

    // const handleImageChange = (e) => {
    // const selectedFiles = Array.from(e.target.files);
    // const selectedFiles = [...images ,{(e.target.value)}];
    // const selectedFiles = Array.from(e.target.files);
    // const newImages = selectedFiles.map(file => ({ file }));
    // setImages([...images, ...newImages]);
    // setImages(selectedFiles);
    // };
    const handleImageChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const newImages = selectedFiles.map(file => ({ file }));
        setImages([...images, ...newImages]);
    };


    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Eng Name</label>
                                <input type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="eng name" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Hindi Name</label>
                                <input type="text" className="form-control" name="name" value={hindiName} onChange={(e) => setHindiName(e.target.value)} placeholder="hindi name" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Place</label>
                                <input type="text" className="form-control" name="place" value={place} onChange={(e) => setPlace(e.target.value)} placeholder="eng place" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Hindi Place</label>
                                <input type="text" className="form-control" name=" hindi place" value={hindiPlace} onChange={(e) => setHindiPlace(e.target.value)} placeholder="hindi place" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Sub Title</label>
                                <input type="text" className="form-control" name="desc" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} placeholder="description" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"> Hindi Sub Title</label>
                                <input type="text" className="form-control" name="desc" value={hindiSubTitle} onChange={(e) => setHindiSubTitle(e.target.value)} placeholder="hindi sub title" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"> English About</label>
                                <input type="text" className="form-control" name="desc" value={about} onChange={(e) => setAbout(e.target.value)} placeholder="eng about" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Hindi About</label>
                                <input type="text" className="form-control" name="desc" value={hindiAbout} onChange={(e) => setHindiAbout(e.target.value)} placeholder="hindi about" />
                            </div>
                        </Grid>

                        <Grid item xs={6}  >
                            <div className="form-group">
                                <label for="exampleInputEmail1">Thumbnail Image</label>
                                <input type="file" placeholder="" className="form-control" multiple onChange={(e) => setThumbnailImage(e.target.files[0])}></input>
                            </div>
                        </Grid>
                        <Grid className="form-group" item xs={6}  >
                            <div>
                                <label for="exampleInputEmail1"> Images</label>
                                <input type="file" placeholder="" name="images" multiple={true} className="form-control" onChange={handleImageChange}></input>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="form-group">
                                <label htmlFor="">Plans</label>
                                <Table className="form-control">
                                    <TableHead>
                                        <TableCell align="center">Duration</TableCell>
                                        <TableCell align="center">price</TableCell>
                                        <TableCell align="center">Add data</TableCell>
                                    </TableHead>
                                    {plans?.map((record, index) => (
                                        <TableBody>
                                            <TableCell align="center">
                                                <input
                                                    type="text"
                                                    name="duration"
                                                    className="form-control"
                                                    value={record.duration}
                                                    onChange={(event) => handlePlansChange(index, event)}
                                                    placeholder="plan duration"
                                                />{" "}
                                            </TableCell>
                                            <TableCell align="center">
                                                <input
                                                    type="text"
                                                    name="price"
                                                    className="form-control"
                                                    value={record.price}
                                                    onChange={(event) => handlePlansChange(index, event)}
                                                    placeholder="plan price"
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <div className={s["action_btn"]}>
                                                    <button className={s["add_btn"]}
                                                        onClick={addPlansRecord}
                                                    >
                                                        <MdOutlineAddCard size={20} /></button>
                                                    <button className={s["delete_btn"]}
                                                        onClick={() => deletePlansRow(index)}
                                                    ><MdDelete size={20} /></button>
                                                </div>
                                            </TableCell>
                                        </TableBody>))}
                                </Table>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="form-group">
                                <label htmlFor=""> English Benefits</label>
                                <Table className="form-control">
                                    <TableHead>
                                        <TableCell align="center">Heading</TableCell>
                                        <TableCell align="center">Value</TableCell>
                                        <TableCell align="center">Add data</TableCell>
                                    </TableHead>
                                    {benefits?.map((record, index) => (
                                        <TableBody>
                                            <TableCell align="center">
                                                <input
                                                    type="text"
                                                    name="heading"
                                                    className="form-control"
                                                    value={record.heading}
                                                    onChange={(event) => handleBenefitChange(index, event)}
                                                    placeholder="eng benefit heading"
                                                />{" "}
                                            </TableCell>
                                            <TableCell align="center">
                                                <input
                                                    type="text"
                                                    name="value"
                                                    className="form-control"
                                                    value={record.value}
                                                    onChange={(event) => handleBenefitChange(index, event)}
                                                    placeholder=" eng benefit Value"
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <div className={s["action_btn"]}>
                                                    <button className={s["add_btn"]} onClick={addBenefitRecord}><MdOutlineAddCard size={20} /></button>
                                                    <button className={s["delete_btn"]} onClick={() => deleteBenefitRow(index)}><MdDelete size={20} /></button>
                                                </div>
                                            </TableCell>
                                        </TableBody>))}

                                </Table>
                                {/* <div className={s["submit_btn"]}>
                    <button onClick={handleData}>Submit</button>
                  </div> */}
                                {/* <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="name....."
                /> */}
                            </div>
                        </Grid>

                        {/* Hindi Benefits */}
                        <Grid item xs={12}>
                            <div className="form-group">
                                <label htmlFor="">Hindi Benefits</label>
                                <Table className="form-control">
                                    <TableHead>
                                        <TableCell align="center">Heading</TableCell>
                                        <TableCell align="center">Value</TableCell>
                                        <TableCell align="center">Add data</TableCell>
                                    </TableHead>


                                    {hindiBenefits?.map((record, index) => (
                                        <TableBody>
                                            <TableCell align="center">
                                                <input
                                                    type="text"
                                                    name="heading"
                                                    className="form-control"
                                                    value={record.heading}
                                                    onChange={(event) => handleHindiBenefitChange(index, event)}
                                                    placeholder="hindi benefit heading"
                                                />{" "}
                                            </TableCell>
                                            <TableCell align="center">
                                                <input
                                                    type="text"
                                                    name="value"
                                                    className="form-control"
                                                    value={record.value}
                                                    onChange={(event) => handleHindiBenefitChange(index, event)}
                                                    placeholder="hindi benefit value"
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <div className={s["action_btn"]}>
                                                    <button className={s["add_btn"]} onClick={addHindiBenefitRecord}><MdOutlineAddCard size={20} /></button>
                                                    <button className={s["delete_btn"]} onClick={() => deleteHindiBenefitRow(index)}><MdDelete size={20} /></button>
                                                </div>
                                            </TableCell>
                                        </TableBody>))}
                                </Table>
                            </div>
                        </Grid>
                        {/* faqs */}
                        <Grid item xs={12}>
                            <div className="form-group">
                                <label htmlFor="">English FAQs</label>
                                <Table className="form-control">
                                    <TableHead>
                                        <TableCell align="center">Heading</TableCell>
                                        <TableCell align="center">Value</TableCell>
                                        <TableCell align="center">Add data</TableCell>
                                    </TableHead>


                                    {faqs?.map((record, index) => (
                                        <TableBody>
                                            <TableCell align="center">
                                                <input
                                                    type="text"
                                                    name="heading"
                                                    className="form-control"
                                                    value={record.heading}
                                                    onChange={(event) => handleFaqsChange(index, event)}
                                                    placeholder="Faqs heading"
                                                />{" "}
                                            </TableCell>
                                            <TableCell align="center">
                                                <input
                                                    type="text"
                                                    name="value"
                                                    className="form-control"
                                                    value={record.value}
                                                    onChange={(event) => handleFaqsChange(index, event)}
                                                    placeholder=" eng Faq Value"
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <div className={s["action_btn"]}>
                                                    <button className={s["add_btn"]} onClick={addFaqsRecord}><MdOutlineAddCard size={20} /></button>
                                                    <button className={s["delete_btn"]} onClick={() => deleteFaqsRow(index)}><MdDelete size={20} /></button>
                                                </div>
                                            </TableCell>
                                        </TableBody>))}
                                </Table>
                            </div>
                        </Grid>
                        {/* Hindi Faqs */}
                        <Grid item xs={12}>
                            <div className="form-group">
                                <label htmlFor="">Hindi FAQs</label>
                                <Table className="form-control">
                                    <TableHead>
                                        <TableCell align="center">Heading</TableCell>
                                        <TableCell align="center">Value</TableCell>
                                        <TableCell align="center">Add data</TableCell>
                                    </TableHead>


                                    {hindiFaqs?.map((record, index) => (
                                        <TableBody>
                                            <TableCell align="center">
                                                <input
                                                    type="text"
                                                    name="heading"
                                                    className="form-control"
                                                    value={record.heading}
                                                    onChange={(event) => handleHindiFaqsChange(index, event)}
                                                    placeholder="Faqs Hindi heading"
                                                />{" "}
                                            </TableCell>
                                            <TableCell align="center">
                                                <input
                                                    type="text"
                                                    name="value"
                                                    className="form-control"
                                                    value={record.value}
                                                    onChange={(event) => handleHindiFaqsChange(index, event)}
                                                    placeholder=" Hindi Faq Value"
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <div className={s["action_btn"]}>
                                                    <button className={s["add_btn"]} onClick={addHindiFaqsRecord}><MdOutlineAddCard size={20} /></button>
                                                    <button className={s["delete_btn"]} onClick={() => deleteHindiFaqsRow(index)}><MdDelete size={20} /></button>
                                                </div>
                                            </TableCell>
                                        </TableBody>))}
                                </Table>
                            </div>
                        </Grid>
                        {/* <Grid item xs={6}  >
                            <div className="form-group">
                                <label for="exampleInputEmail1">Benefits heading</label>
                                <input type="text" placeholder="benefit heading" value={benefitHeading} className="form-control" onChange={(e) => setBenefitHeading(e.target.value)}></input>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className = "form-group">
                                <label for="exampleInputEmail1">Benefits Value</label>
                                <input type="text" placeholder="benefit value" value={benefitValue} className="form-control" onChange={(e) => setBenefitValue(e.target.value)}></input>
                            </div>
                        </Grid> */}
                    </Grid>

                    <div className={s["form-login-btn"]} onClick={() => addSubscriptionFunc()}>
                        <Custombutton>{pagetype === "Add" ? "Submit" : "Update"}</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};
export default AddSubscription;

