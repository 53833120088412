import React from "react";
import EventAdd from "../components/Event/EventAdd";

const AddEvent = () => {
  return (
    <div>
      <EventAdd />
    </div>
  );
};

export default AddEvent;
