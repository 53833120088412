import React from "react";
import Errorpage1 from "../components/ErrorPage/ErrorPage1";

const Errorpage = () => {
  return (
    <div>
      <Errorpage1 />
    </div>
  );
};

export default Errorpage;
