import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";

let all_appdata_list = getBaseUrl() + "admin/appData";
let update_appdata = getBaseUrl() + "admin/appData/";


export const get_all_appdata_list = async (data) => {
  let config = {
    method: "get",
    url: all_appdata_list,
    // headers: { Authorization: `Bearer : ${Cookies.get("token")}` },
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const update_appdata_api = async (data) => {
  // let id = data.get("id")

  let config = {
    method: "patch",
    url: update_appdata + `${data.id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
