import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./certificate.module.css"
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Pagination } from "@mui/material";
import { fetchallCertificate, user_delete_api, user_status_api } from "../api/user";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiArrowBack, BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import DeletePopup from "../Dialogbox/DeletePopup";
import { getBaseUrl, getBaseUrl2 } from "../../utils";
import { certificate_delete_api, fetchAllUserCertificate } from "../api/certificate";
import { IoMdAdd } from "react-icons/io";


import { saveAs } from 'file-saver';


const CertificateList = () => {
    const navigate = useNavigate();
    const location = useLocation()
    console.log(location.state.data, "this is used to get teh user id og the particular user")
    const [isLoading, setisLoading] = useState(false);
    const [allCertificate, setallCertificate] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletedialobbox, setdeletedialobbox] = useState(false);
    const [deletename, setdeletename] = useState();
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    useEffect(() => {
        fetchAllCertificateFunc();
    }, [pageCount, search]);

    async function fetchAllCertificateFunc(data) {
        // setisLoading(true);
        try {
            const temp = {
                id: location?.state?.data
            };
            let res = await fetchAllUserCertificate(temp);
            console.log(res.data.data, "res data in the list")
            // return;
            if (res.data.status) {
                setallCertificate(res.data.data);
                // setpageLength(res?.data?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    async function deleteCertificateFunc() {
        setisLoading(true);
        let temp = {
            id: deleteId,
        };
        try {
            let res = await certificate_delete_api(temp);
            console.log(res);
            if (res.data.status) {
                setisLoading(false);
                setdeletedialobbox(false);
                // fetchAllCertificateFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    }



    const downloadImage = async (data) => {

        let downloadUrl = getBaseUrl2() + data.certificate
        try {
            const response = await fetch(downloadUrl);
            const blob = await response.blob();
            saveAs(blob,);
        } catch (error) {
            console.error('Download failed:', error);
        }
    }

    return (
        // <div>
        //     This is used to certificate page.
        // </div>
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Certificate List</h3>
                    </div>
                </div>

                <div className={s["user-list-search"]}>
                    <div className="beat_left">
                        {/* <div
                            className={s["title"]}
                            onClick={() =>
                                navigate("/add-certificate", {
                                    state: {
                                        pagetype: "Add",
                                    },
                                })
                            }
                        >
                            <IoMdAdd /> Certificate
                        </div> */}
                    </div>

                </div>

            </div>
            <div className="main-heading-left">
                <div className={s["title"]} onClick={() => navigate(-1)}>
                    <BiArrowBack />
                    Back
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Image</StyledTableCell>
                            <StyledTableCell>Download</StyledTableCell>
                            {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {allCertificate.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                    {row.certificate ? <img style={{ height: "10rem", width: "fitContent" }} src={getBaseUrl2() + row.certificate} alt="" /> : null}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <button className={s["hover_btn"]} onClick={() => downloadImage(row)}>Download</button>
                                </StyledTableCell>
                                {/* <StyledTableCell align="center">
                                    <CiEdit
                                        onClick={() =>
                                            navigate("/certificate_list", {
                                                state: {
                                                    pagetype: "Edit",
                                                    data: row,
                                                },
                                            })
                                        }
                                        style={{
                                            fontSize: "1rem",
                                            color: "var(--clr-primary)",
                                            marginRight: "0.5rem",
                                            cursor: "pointer",
                                        }}
                                    />
                                </StyledTableCell> */}
                                {/* <StyledTableCell align="center">
                                    <div style={{ cursor: "pointer" }} onClick={() => user_status(row)} className={`${row.status === true ? s.active_admin : s.inactive_admin}`}>
                                        {`${row.status === true ? "Active" : "InActive"}`}
                                    </div>
                                </StyledTableCell> */}
                                {/* <StyledTableCell align="center">
                                    <MdDelete
                                        onClick={() => {
                                            setdeletedialobbox(true);
                                            setdeleteId(row.id);
                                        }}
                                        style={{ fontSize: "1rem", color: "var(--clr-primary)", cursor: "pointer" }}
                                    />
                                </StyledTableCell> */}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {allCertificate.length <= 0 && <DataNotFound />}
                {allCertificate?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )}
            </div>
            <DeletePopup
                open={deletedialobbox}
                name={deletename}
                close={() => setdeletedialobbox(!deletedialobbox)}
                onsubmit={() => {
                    deleteCertificateFunc();
                }}
            />
            <Loder loading={isLoading} />
        </div >
    );
};

export default CertificateList;
