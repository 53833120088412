import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./reviews.module.css";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { BiFilter, BiSearch } from "react-icons/bi";
import DeletePopup from "../Dialogbox/DeletePopup";
import FilterPopup from "../Dialogbox/FilterPopup";
import { deleteTestimonial_api, getReview_api, review_status_detail } from "../api/review";
import { getBaseUrl2 } from "../../utils";

const ReviewList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allReviews, setAllReviews] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState();
  const [deletedialobbox, setdeletedialobbox] = useState(false);
  const [deletename, setdeletename] = useState();
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  useEffect(() => {
    fetchAllReviewFunc();
  }, [pageCount, search]);

  async function fetchAllReviewFunc(data) {
    // setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getReview_api(temp);
      if (res.data.status) {
        setpageLength(res?.data?.data?.totalPages);
        setAllReviews(res?.data?.data.reviews);
        // setisLoading(false);
      } else {
        // setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteroleFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deleteTestimonial_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletedialobbox(false);
        fetchAllReviewFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const review_status = async (data) => {
    setisLoading(true);
    let temp = {
      id: data._id,
      isApproved: data.isApproved === true ? false : true
    }
    console.log(temp, "temp data------------------------------------------")
    try {
      let res = await review_status_detail(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchAllReviewFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  return (
    <div className="container">
      <div className={s["role-list-title"]}>
        <h3>Review List</h3>
      </div>
      <div className={s["user-list-heading"]}>
        <div className={s["user-list-title"]}>
          <div className="beat_left">
            <div
              className={s["title"]}
              onClick={() =>
                navigate("/add-review", {
                  state: {
                    pagetype: "Add",
                  },
                })
              }
            >
              <IoMdAdd /> Review
            </div>
          </div>
        </div>
        <div className={s["user-list-search"]}>
          <div className={s["search-box"]}>
            <span style={{ paddingRight: "0.5rem" }}>
              <BiSearch size={23} />
            </span>
            <input
              type="text"
              spellCheck="false"
              onChange={(e) => {
                setpageCount(1);
                setsearch(e.target.value);
              }}
              placeholder="Search name..."
            />
          </div>
          <div className={s["filter-btn"]}>
            <span style={{ paddingRight: "2px" }}>
              <BiFilter size={20} />
            </span>
            Filter
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Image</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Review</StyledTableCell>
              <StyledTableCell align="center">Rating</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              {/* <StyledTableCell align="center">Action</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {allReviews.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{row?.user?.profile_image?.url ? <img style={{ height: "2rem", width: "3rem" }} src={getBaseUrl2() + row?.user?.profile_image?.url} alt="" /> : null}</StyledTableCell>
                <StyledTableCell align="center">{row?.user?.name}</StyledTableCell>
                <StyledTableCell align="center" style={{ width: "40%" }}>
                  {row?.review}
                </StyledTableCell>
                <StyledTableCell align="center">{row.rating}</StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => review_status(row)}
                    className={`${row.isApproved === true ? s.active_admin : s.inactive_admin}`}
                  >
                    {row.isApproved === true ? "Active" : "InActive"}
                  </div>
                </StyledTableCell>
                {/* <StyledTableCell align="center">
                  <CiEdit
                    onClick={() =>
                      navigate("/testimonial-add", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                  <MdDelete
                    onClick={() => {
                      setdeletedialobbox(true);
                      setdeletename(row.name);
                      setdeleteId(row.id);
                    }}
                    style={{ fontSize: "1rem", color: "var(--clr-primary)", cursor: "pointer" }}
                  />
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allReviews.length <= 0 && <DataNotFound />}
        {allReviews?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
          </div>
        )}
      </div>

      <DeletePopup
        open={deletedialobbox}
        name={deletename}
        close={() => setdeletedialobbox(!deletedialobbox)}
        onsubmit={() => {
          deleteroleFunc();
        }}
      />
      <FilterPopup open={false} />

      <Loder loading={isLoading} />
    </div>
  );
};

export default ReviewList;
