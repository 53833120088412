import React, { useEffect } from "react";
import s from "./event.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addevent_api, updateevent_api } from "../api/event";
import { useForm } from "react-hook-form";

const EventAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const pagetype = location.state.pagetype;

  useEffect(() => {
    if (location.state.data) {
      setValue("name", location.state.data.name);
      let Date = location.state.data.date?.toString()?.slice(0, 10);
      setValue("date", Date);
      setValue("desc", location.state.data.desc);
      setValue("hindiDesc", location.state.data.hindiDesc);
      setValue("url", location.state.data.url);
      setValue("price", location.state.data.price);
      setValue("location", location.state.data.location);
      setValue("hindiLocation", location.state.data.location);
      setValue("priority", location.state.data.priority);
      setValue("titleHindi", location.state.data.titleHindi);
      setValue("INRPrice", location.state.data.price);
      setValue("status", location?.state?.data?.status);
    }
  }, [location, setValue]);

  const onSubmit = async (data) => {
    const fd = new FormData();
    fd.append("name", data.name);
    fd.append("location", data.location);
    fd.append("hindiLocation", data.hindiLocation);
    fd.append("date", data.date);
    fd.append("desc", data.desc);
    fd.append("hindiDesc", data.hindiDesc);
    fd.append("priority", data.priority);
    fd.append("url", data.url);
    fd.append("status", data.status);
    fd.append("price", data.INRPrice);
    // fd.append("USDPrice", data.USDPrice);
    fd.append("titleHindi", data.titleHindi);
    if (data.thumbnail) {
      Object.keys(data.thumbnail).forEach((key) => {
        fd.append("thumbnail", data.thumbnail[key]);
      });
    }

    try {
      let res;
      if (pagetype === "Add") {
        res = await addevent_api(fd);
      } else {
        fd.append("id", location?.state?.data?._id);
        res = await updateevent_api(fd, location?.state?.data?._id);
      }

      if (res.data.status) {
        navigate("/event-list");
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "warning", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  return (
    <div className="">
      <Card className={s["admin_container"]}>
        <div className={s["title"]} onClick={() => navigate(-1)}>
          <BiArrowBack />
          Back
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="name">English Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  {...register("name", { required: true })}
                />
                {errors.name && <span>This field is required</span>}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="name">Hindi Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="titleHindi"
                  placeholder="hindi name"
                  {...register("titleHindi", { required: true })}
                />
                {errors.titleHindi && <span>This field is required</span>}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="thumbnail">Thumbnail(Multiple Images)</label>
                <input
                  type="file"
                  className="form-control"
                  name="thumbnail"
                  multiple
                  accept="image/*"
                  {...register("thumbnail", { required: pagetype === "Add" })}
                />
                {errors.thumbnail && <span>This field is required</span>}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="eventlocation">English Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  placeholder="Location"
                  {...register("location", { required: true })}
                />
                {errors.location && <span>This field is required</span>}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="eventlocation">Hindi Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="hindiLocation"
                  placeholder="hindiLocation"
                  {...register("hindiLocation", { required: true })}
                />
                {errors.hindiLocation && <span>This field is required</span>}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  placeholder="Date"
                  {...register("date", { required: pagetype === "Add" })}
                />
                {errors.date && <span>This field is required</span>}
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="priority">Priority</label>
                <input
                  type="text"
                  className="form-control"
                  name="priority"
                  placeholder="Priority"
                  {...register("priority", { required: true })}
                />
                {errors.priority && <span>This field is required</span>}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="price"> INR Price</label>
                <input
                  type="text"
                  className="form-control"
                  name="INRPrice"
                  placeholder="Price"
                  {...register("INRPrice", { required: true })}
                />
                {errors.INRPrice && <span>This field is required</span>}
              </div>
            </Grid>
            {/* <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="price"> USD Price</label>
                <input
                  type="text"
                  className="form-control"
                  name="USDPrice"
                  placeholder="Price"
                  {...register("USDPrice", { required: true })}
                />
                {errors.USDPrice && <span>This field is required</span>}
              </div>
            </Grid> */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="price"> Status</label>
                <select
                  className="form-control"
                  name="status"
                  // value={status}
                  {...register("status", { required: true })}
                >
                  <option value="">select Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">In-Active</option>
                </select>
                {errors.status && <span>This field is required</span>}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label htmlFor="desc"> English Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="desc"
                  placeholder=" English Description"
                  {...register("desc", { required: true })}
                  rows="8"
                />
                {errors.desc && <span>This field is required</span>}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label htmlFor="hindiDesc">Hindi Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="hindiDesc"
                  placeholder="hindi Description"
                  {...register("hindiDesc", { required: true })}
                  rows="8"
                />
                {errors.hindiDesc && <span>This field is required</span>}
              </div>
            </Grid>
          </Grid>
          <div className={s["form-login-btn"]}>
            <Custombutton type="submit">
              {" "}
              {pagetype === "Edit" ? "Update" : "Submit"}
            </Custombutton>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EventAdd;
