import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const certificate_delete = getBaseUrl() + "admin/deleteUser";
const user_update = getBaseUrl() + "admin/updateUser";
const certificate_list = getBaseUrl() + "admin/certificate";
const add_certificate = getBaseUrl() + "admin/createCertificate"

export const user_update_api = async (data) => {
    let config = {
        method: "patch",
        url: user_update,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};


export const add_certificate_api = async (data) => {
    let config = {
        method: "post",
        url: add_certificate,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};


export const fetchAllUserCertificate = async (data) => {
    let config = {
        method: "get",
        url: certificate_list + `/${data.id}`,
        // url: users_list,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        // data: data,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const certificate_delete_api = async (data) => {
    let config = {
        method: "DELETE",
        url: certificate_delete,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};