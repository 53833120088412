import React from "react";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/profile";
import UserAdd from "./pages/UserAdd";
import Users from "./pages/Users";
import Category from "./pages/Category";
import AddCategoryPage from "./pages/AddCategoryPage";
import Banner from "./pages/Banner";
import AddBanner from "./pages/AddBanner";
import AddAstrologer from "./pages/AddEvent";
import { LiveDarshansList } from "./pages/LiveDarshansList";
import LiveDarshansAdd from "./pages/LiveDarshansAdd";
import LibraryMediaList from "./components/LibraryMedia/LibraryMediaList";
import AddLibraryMedia from "./components/LibraryMedia/AddLibraryMedia";
import Event from "./pages/Event";
import FeatureMediaList from "./components/FeaturedMedia/FeatureMediaList";
import AddFeatureMedia from "./components/FeaturedMedia/AddFeatureMedia";
import TagList from "./components/Tags/TagList";
import AddTags from "./components/Tags/AddTags";
import AppDataEdit from "./components/AppData/AppDataEdit";
import BlogData from "./components/Blogs/blogs";
import AddBlog from "./components/Blogs/AddBlog";
import HolidayList from "./components/Holiday/Holiday";
import AddHoliday from "./components/Holiday/AddHoliday";
import ReviewList from "./components/Reviews/ReviewList";
import AddEvent from "./pages/AddEvent";
import SubscriptonList from "./components/Subscription/SubscriptionList";
import AddSubscription from "./components/Subscription/AddSubscription";
import ReviewAdd from "./components/Reviews/ReviewAdd";
import UserCertificate from "./components/UploadCertificate/UserCertificate";
import CertificateList from "./components/UploadCertificate/CertificateList";
import AddCertificate from "./components/UploadCertificate/AddCertificate";
import TransactionList from "./components/Users/TransactionList";
import SubscriptionTransactionList from "./components/Users/SubscriptionTransactionList";
import EditLibraryMedia from "./components/LibraryMedia/EditLibraryMedia";
import AdminTranactionList from "./components/AdminTransaction/AdminTransactionList";
import SubscriptionTransactionOuterList from "./components/SubscriptionTransaction/SubscriptionTransactionOuterList";

const routeArray = [
  // { params: undefined, component: <Home1 /> },
  { params: "users", component: <Users /> },
  { params: "transaction-list", component: <TransactionList /> },
  { params: "Subscription-transaction-list", component: <SubscriptionTransactionList /> },
  { params: "dashboard", component: <Dashboard /> },
  { params: "useradd", component: <UserAdd /> },
  { params: "profile", component: <Profile /> },
  { params: "live-darshans", component: <LiveDarshansList /> },
  { params: "add-live-darshans", component: <LiveDarshansAdd /> },
  { params: "library-media", component: <LibraryMediaList /> },
  { params: "add-library-media", component: <AddLibraryMedia /> },
  { params: "edit-library-media", component: <EditLibraryMedia /> },
  { params: "category-list", component: <Category /> },
  { params: "add-category", component: <AddCategoryPage /> },
  { params: "banner-list", component: <Banner /> },
  { params: "add-banner", component: <AddBanner /> },
  { params: "event-list", component: <Event /> },
  { params: "event-add", component: <AddEvent /> },

  // Feature Media
  { params: "feature-list", component: <FeatureMediaList /> },
  { params: "add-feature-media", component: <AddFeatureMedia /> },

  // Tag
  { params: "tag-list", component: <TagList /> },
  { params: "add-tag", component: <AddTags /> },

  //App Data

  { params: "app-data", component: <AppDataEdit /> },

  // Blogs
  { params: "blog-list", component: <BlogData /> },
  { params: "add-blog", component: <AddBlog /> },

  //Hlodays

  { params: "holiday-list", component: <HolidayList /> },
  { params: "add-holiday", component: <AddHoliday /> },

  // Review List
  { params: "review-list", component: <ReviewList /> },
  { params: "add-review", component: <ReviewAdd /> },

  // Subscription
  { params: "subscription-list", component: <SubscriptonList /> },
  { params: "add-subscription", component: <AddSubscription /> },

  // certificate
  { params: "upload-certificate", component: <UserCertificate /> },
  { params: "certificate-list", component: <CertificateList /> },
  { params: "add-certificate", component: <AddCertificate /> },

  //  admin Transaction Api
{ params: "admin-transaction", component: <AdminTranactionList /> },


  //  subscription Transaction Api
{ params: "subscription-transaction", component: <SubscriptionTransactionOuterList /> },

];

export default routeArray;
