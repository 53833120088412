import React from "react";
import AddLiveDarshans from "../components/LiveDarshans/AddLiveDarshans";

const LiveDarshansAdd = () => {
  return (
    <>
      <AddLiveDarshans />
    </>
  );
};

export default LiveDarshansAdd;
