import React, { useState, useEffect } from "react";
import s from "./blog.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addBanner_api, updateBanner_api } from "../api/banner";
import { add_blog_api, updateBlog_api } from "../api/blog";
import RichTextEditor from "react-rte-17";
const AddBlog = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [title, settitle] = useState("");
  const [author, setAuthor] = useState("");
  const [description, setDescription] = useState("");
  const [file, setfile] = useState(null);
  const [icon, setIcon] = useState(null);
  const [hindiTitle, setHindiTitle] = useState("");
  const [status, setStatus] = useState("");
  const [hindiDescription, setHindiDescription] = useState("")
  const [priority, setPriority] = useState("")
  const [engBlog, setEngBlog] = useState(RichTextEditor.createEmptyValue())
  const [hinBlog, setHinBlog] = useState(RichTextEditor.createEmptyValue())
  const pagetype = location.state.pagetype;
  useEffect(() => {
    if (pagetype === "Edit") {
      settitle(location?.state?.data?.title);
      setHindiTitle(location?.state?.data?.hindiTitle);
      setAuthor(location?.state?.data?.author);
      setStatus(location?.state?.data?.status);
      setPriority(location?.state?.data?.priority);
      setDescription(location?.state?.data?.description);
      const initialEditorState = RichTextEditor.createValueFromString(
        location?.state?.data?.description,
        "html"
      );
      const initialEditorState2 = RichTextEditor.createValueFromString(
        location?.state?.data?.hindiDescription,
        "html"
      );
      setEngBlog(initialEditorState)
      setHinBlog(initialEditorState2)
    }
    // setredirectUrl(location?.state?.data?.redirectUrl);
    // setpriority(location?.state?.data?.priority);
  }, [location]);

  const Category_function = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("title", title);
      fd.append("hindiTitle", hindiTitle);
      fd.append("author", author);
      fd.append("image", file);
      fd.append("status", status);
      fd.append("icon", icon);
      fd.append("priority", priority);
      fd.append("description", engBlog.toString("html"));
      fd.append("hindiDescription", hinBlog.toString("html"));

      try {
        let res = await add_blog_api(fd);
        if (res.data.status) {
          navigate("/blog-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("id", location?.state?.data?._id);
      fd.append("title", title);
      fd.append("hindiTitle", hindiTitle);
      fd.append("author", author);
      fd.append("priority", priority);
      fd.append("status", status);
      if (file) {
        fd.append("image", file);
      }
      if (icon) {
        fd.append("icon", icon);
      }
      fd.append("description", engBlog.toString("html"));
      fd.append("hindiDescription", hinBlog.toString("html"));
      try {
        let res = await updateBlog_api(fd);
        if (res.data.status) {
          navigate("/blog-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  const onChange1 = (value) => {
    setEngBlog(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  }

  const onChange2 = (value) => {
    console.log(value, "value ===========================================================================================")
    setHinBlog(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1"> English Title</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder=""
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1"> Hindi Title</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder=""
                    value={hindiTitle}
                    onChange={(e) => setHindiTitle(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input
                    type="file"
                    className="form-control"
                    name="img"
                    placeholder=""
                    accept="image/*"
                    onChange={(e) => setfile(e.target.files[0])}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Author</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    name="author"
                    placeholder=""
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="group-form">
                <label htmlFor="">Icon</label>
                <div className="mr-2">
                  <input
                    type="file"
                    className="form-control"
                    placeholder=""
                    // value={link}
                    onChange={(e) => setIcon(e.target.files[0])}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Status</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      id="exampleFormControlSelect1"
                    >
                      <option>Select Type </option>
                      <option value="Active">Active </option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}  >
              <div className="form-group">
                <label for="exampleInputEmail1">Priority</label>
                <input type="number" value={priority} placeholder="select priority" className="form-control" onChange={(e) => setPriority(e.target.value)}></input>
              </div>
            </Grid>
            {/* <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">English Description</label>
                <div className="mr-2">
                  <textarea
                    type="text"
                    className="form-control"
                    name="redirectUrl"
                    placeholder=""
                    value={description}
                    rows="8"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            </Grid> */}
            {/* <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Hindi Description</label>
                <div className="mr-2">
                  <textarea
                    type="text"
                    className="form-control"
                    name="redirectUrl"
                    placeholder=""
                    value={hindiDescription}
                    rows="8"
                    onChange={(e) => setHindiDescription(e.target.value)}
                  />
                </div>
              </div>
            </Grid> */}
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">English Description</label>
                <RichTextEditor
                  value={engBlog}
                  className="employment_jobdescribee"
                  onChange={onChange1}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-control">
                <label>Hindi Description</label>
                <RichTextEditor
                  value={hinBlog}
                  className="employment_jobdescribee"
                  onChange={onChange2}
                >

                </RichTextEditor>
              </div>
            </Grid>
          </Grid>
          <div
            className={s["form-login-btn"]}
            onClick={() => Category_function()}
          >
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddBlog;
