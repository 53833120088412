import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Custombutton from "../../Common/Custombutton";
import { Card, Grid } from "@mui/material";
import { add_tag_api, update_tag_api } from "../api/tag";
import s from "./certificate.module.css";
import { add_certificate_api } from "../api/certificate";
import { notificationHandler } from "../../utils/Notification";

const AddCertificate = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [certificate, setCertificate] = useState(null);

    let pagetype = location?.state?.pagetype;
    let id = location?.state?.data;

    const create_certificate = async () => {
        console.log("this is oj")
        if (pagetype === "Add") {
            const fd = new FormData()
            fd.append("userId", id)
            fd.append("certificate", certificate)
            try {
                let res = await add_certificate_api(fd);
                if (res.data.status) {
                    navigate("/upload-certificate");
                    notificationHandler({ type: "success", msg: res?.data?.message });
                } else {
                    notificationHandler({ type: "success", msg: res?.data?.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error?.message });
                console.log(error);
            }
        }
        if (pagetype == "Edit") {
            let temp = {
                name: name,
            };
            try {
                let res = await update_tag_api(temp, location?.state?.data?._id);
                if (res.data.status) {
                    navigate("/tag-list");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
    };

    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <IoArrowBack size={25} /> Back
                    </div>
                    <Grid
                        xs={12}
                        conatiner
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label htmlFor="">Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="certificate"
                                    // value={thumbnail}
                                    onChange={(e) => setCertificate(e.target.files[0])}
                                    placeholder="certificate Image"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <div className={s["form-login-btn"]} onClick={() => create_certificate()}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AddCertificate;
