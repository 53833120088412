import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";

let holiday_list = getBaseUrl() + "admin/holiday";
let add_holiday = getBaseUrl() + "admin/holiday";
let delete_holiday = getBaseUrl() + "admin/holiday";
let update_holiday = getBaseUrl() + "admin/holiday"

export const get_all_holiday = async (data) => {
    let config = {
        method: "get",
        url: holiday_list + `/${data.year}/${data.month}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` }
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}


export const addHoliday_api = async (data) => {
    console.log(data, "data onhte page of js888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888")
    let config = {
        method: "post",
        url: add_holiday,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}
export const updateHoliday_api = async (data) => {
    console.log(data, "data onhte page of js88888888888888888888888888888888888888")
    const year = data.year
    const month = data.month
    // console.log(data.get("year"), "this is get the data from the fd to inser into the url")
    // return;
    let config = {
        method: "put",
        url: update_holiday + `/${year}/${month}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}

export const deleteHoliday_api = async (data) => {
    let config = {
        method: "delete",
        url: delete_holiday + `/${data?.year}/${data?.month}/${data.id}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}

