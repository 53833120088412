import React, { useState, useEffect } from "react";
import s from "./holiday.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addTestimonial_api, updateTestimonial_api } from "../api/review";
import { addevent_api, updateevent_api } from "../api/event";
import { addHoliday_api, updateHoliday_api } from "../api/hoilday";
const AddHoliday = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [date, setdate] = useState("");
    const [month, setMonth] = useState()
    const [year, setYear] = useState()
    const [holiday, setHoliday] = useState({ name: "", hindiName: "", date: "", description: "", hindiDescription: "" })

    console.log(holiday, "holiday send  i thr updtion")
    const pagetype = location.state.pagetype;
    // console.log(location.state.data, month, year, "data in the pagetype in the edit of the")

    useEffect(() => {
        if (pagetype === "Edit") {
            console.log("hey buddy ")
            let month = new Date(location.state.data.date).getMonth() + 1;
            let year = new Date(location.state.data.date).getFullYear()
            setMonth(month)
            setYear(year)
            setHoliday({
                name: location?.state?.data.name,
                hindiName: location?.state?.data?.hindiName,
                description: location?.state?.data?.description,
                hindiDescription: location?.state?.data?.hindiDescription,
                date: (location.state.data.date)?.toString()?.slice(0, 10)
            })
        }
    }, []);

    const addevent_function = async () => {
        if (pagetype == "Add") {
            //     const fd = new FormData();
            //     fd.append("year", year)
            //     fd.append("month", month)
            //     fd.append("holidays", [holiday])
            let temp = {
                year: year,
                month: month,
                holidays: [holiday]
            }
            try {
                let res = await addHoliday_api(temp);
                if (res.data.status) {
                    navigate("/holiday-list");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
        if (pagetype == "Edit") {
            // const fd = new FormData();
            // fd.append("year", year)
            // fd.append("month", month)
            // fd.append("holidays", [holiday])
            // fd.append("id", location?.state?.data?._id);
            let temp = {
                year: year,
                month: month,
                holidays: holiday,
                id: location?.state?.data?._id
            }
            try {
                let res = await updateHoliday_api(temp, location?.state?.data?._id);
                if (res.data.status) {
                    console.log(res);
                    navigate("/holiday-list");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
    };



    const handleDate = (date) => {
        console.log(date, "date value==================================================")
        setMonth((new Date(date).getMonth()) + 1)
        setYear(new Date(date).getFullYear())
        handleHoliday("date", new Date(date).toISOString());
    }

    const handleHoliday = (type, data) => {
        setHoliday(prevHoliday => ({ ...prevHoliday, [type]: data }));
    }

    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Date</label>
                                <input type="date" className="form-control" name="date"
                                    // value={pagetype === "Edit" ? holiday.date : false}
                                    {...(pagetype === "Edit" ? { value: holiday.date } : {})}
                                    onChange={(e) => handleDate(e.target.value)} placeholder="date" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">English Holiday Name</label>
                                <input type="text" className="form-control" name="name" value={holiday.name} onChange={(e) => handleHoliday("name", e.target.value)} placeholder="Name" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Hindi Holiday Name</label>
                                <input type="text" className="form-control" name="hindiName" value={holiday.hindiName} onChange={(e) => handleHoliday("hindiName", e.target.value)} placeholder="Hindi Name" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"> Eng Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    name="desc" value={holiday.description}
                                    onChange={(e) => handleHoliday("description", e.target.value)}
                                    placeholder="description"
                                    rows="5"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Hindi Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={holiday.hindiDescription}
                                    name="desc" onChange={(e) => handleHoliday("hindiDescription", e.target.value)}
                                    placeholder=" hindi description"
                                    rows="5"
                                />
                            </div>
                        </Grid>

                        {/* <Grid item xs={6}  >
                            <div className="form-group">
                                <label for="exampleInputEmail1">Priority</label>
                                <input type="text" value={priority} placeholder="select priority" className="form-control" onChange={(e) => setPriority(e.target.value)}></input>
                            </div>
                        </Grid> */}

                    </Grid>

                    <div className={s["form-login-btn"]} onClick={() => addevent_function()}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AddHoliday;
