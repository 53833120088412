import React, { useState, useEffect } from "react";
import s from "./banner.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { Globalsearch_api, addBanner_api, updateBanner_api } from "../api/banner";
const BannerAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [title, settitle] = useState("");
  const [hindiTitle, setHindiTitle] = useState("");
  const [priority, setpriority] = useState("");
  const [redirectUrl, setredirectUrl] = useState("");
  const [redirectUrlId, setredirectUrlId] = useState("")
  const [file, setfile] = useState(null);
  const [type, setType] = useState("");
  const [, setLink] = useState(null);
  const [linkType, setLinkType] = useState("");
  const [searchdata, setsearchdata] = useState([]);
  const [show, setshow] = useState(false);
  const pagetype = location.state.pagetype;
  // console.log(redirectUrl, linkType, "----------------------------------------------linktype")
  const [value, setvalue] = useState("");

  useEffect(() => {
    settitle(location?.state?.data?.title);
    setredirectUrl(location?.state?.data?.redirectUrl);
    setpriority(location?.state?.data?.priority);
    setHindiTitle(location?.state?.data?.hindiTitle);
    setType(location?.state?.data?.type);
    setLinkType(location?.state?.data?.link_type);
  }, [location]);

  const Category_function = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("title", title);
      fd.append("hindiTitle", hindiTitle);
      fd.append("priority", priority);
      fd.append("redirectUrl", redirectUrl);
      fd.append("redirectUrlId", redirectUrlId);
      fd.append("image", file);
      fd.append("type", type);
      fd.append("link_type", linkType);
      try {
        let res = await addBanner_api(fd);
        if (res.data.status) {
          navigate("/banner-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("id", location?.state?.data?._id);
      fd.append("title", title);
      fd.append("hindiTitle", hindiTitle);
      fd.append("priority", priority);
      fd.append("redirectUrl", redirectUrl);
      fd.append("redirectUrlId", redirectUrlId)
      if (file) {
        fd.append("image", file);
      }
      fd.append("type", type);
      fd.append("link_type", linkType);
      try {
        let res = await updateBanner_api(fd);
        if (res.data.status) {
          navigate("/banner-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  console.log(value, searchdata, "value in the list of ----------------------------------------")   //for making page redirect to detail page of thr banner



  // redirect code

  useEffect(() => {
    datasearchApi();
  }, [value])

  const datasearchApi = async (e) => {

    console.log("asdfgjk")
    setshow(true);
    setvalue(e.target.value);

    let temp = {
      search: e.target.value
    }
    try {
      const res = await Globalsearch_api(temp);
      console.log(res, 'heybruh');
      setsearchdata(res?.data);
      if (res.status) {
        setsearchdata(res?.data?.data);
      }
    } catch (error) { }
  };



  const handleClearSearch = () => {
    setvalue("");    // Reset the search value when the clear icon is clicked
    setshow(false);
    alert("Clear Search");
  };

  const redirect_url_function = (data, type, index) => {
    console.log(data, "data in the redirect url to save into the api for redirection in teh website from the banner")
    console.log(type, "type to used to identify the internal or external type of the banner on the web site")
    // const ulr = `/${type}/${data?._id}`
    let inputValue = data.name ? data?.name : data?.title ? data?.title : data?.titleEnglish
    // console.log(inputValue, "value of the input value in the set value for show in the input box")
    setvalue(inputValue)
    setredirectUrl(type)
    setredirectUrlId(data._id)
  }
  // redirect code

  const handleAlert = () => {
    alert("pleaase Select Banner link type first")
  }
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1"> Eng Title</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="Eng Title"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Hindi Title</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="Hindi Title"
                    value={hindiTitle}
                    onChange={(e) => setHindiTitle(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1"> Banner Type</label>
                <div className="mr-2">
                  <select
                    className="form-control"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option default>Select Type</option>
                    <option value="Web">Web</option>
                    <option value="App">App</option>
                  </select>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input
                    type="file"
                    className="form-control"
                    name="img"
                    placeholder=""
                    accept="image/*"
                    onChange={(e) => setfile(e.target.files[0])}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Priority</label>
                <div className="mr-2">
                  <input
                    type="number"
                    className="form-control"
                    name="priority"
                    placeholder="priority"
                    value={priority}
                    onChange={(e) => setpriority(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Link Type</label>
                <div className="mr-2">
                  <select
                    className="form-control"
                    value={linkType}
                    onChange={(e) => setLinkType(e.target.value)}
                  >
                    <option default>Select Type</option>
                    <option value="internal">Internal</option>
                    <option value="external">External</option>
                  </select>
                </div>
              </div>
            </Grid>
            {

            }


            {linkType === "external" ? <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">RedirectUrl</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    name="redirectUrl"
                    placeholder="external url"
                    value={redirectUrl}
                    onChange={(e) => setredirectUrl(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
              :
              linkType === "internal" ?
                (<Grid item xs={6}>
                  <label htmlFor="">Internal Linking</label>
                  <div className={s["navbar-searchbar"]} style={{ position: "relative" }}>
                    <form class="form-inline my-2 my-lg-0">
                      <input
                        class="form-control mr-sm-2"
                        type="search"
                        placeholder="Search"
                        value={value}
                        onClear={handleClearSearch}
                        // onClick={(e) => datasearchApi(e)}
                        onChange={(e) => {
                          datasearchApi(e);
                          setvalue(e.target.value)
                        }}
                        aria-label="Search"
                      />
                    </form>
                    {value && (
                      <div className={s["search-item"]}>
                        <div>
                          <h6>Events</h6>
                          <ul style={{ padding: "0" }}>
                            {searchdata?.events?.length > 0
                              ? searchdata?.events?.map((data, index) => {
                                return (
                                  <li style={{ cursor: "pointer" }} onClick={() => redirect_url_function(data, "events", index)} key={index}>
                                    {data?.name}
                                  </li>
                                );
                              })
                              : "Event not found"}
                          </ul>
                        </div>
                        <div>
                          <h6>Featured Media</h6>
                          <ul style={{ padding: "0" }}>
                            {searchdata?.featuredMedia?.length > 0
                              ? searchdata?.featuredMedia?.map((data, index) => {
                                return (
                                  <li style={{ cursor: "pointer" }} onClick={() => redirect_url_function(data, "featuredMedia", index)} key={index}>
                                    {data?.titleEnglish}
                                  </li>
                                );
                              })
                              : "FeaturedMedia  not found"}
                          </ul>
                        </div>
                        <div>
                          <h6>Live Darshans</h6>
                          <ul style={{ padding: "0" }}>
                            {searchdata?.liveDarshans?.length > 0
                              ? searchdata?.liveDarshans?.map((data, index) => {
                                return (
                                  <li style={{ cursor: "pointer" }} onClick={() => redirect_url_function(data, "livedarshan", index)} key={index}>
                                    {data?.titleEnglish}
                                  </li>
                                );
                              })
                              : "LiveDarshan not found"}
                          </ul>
                        </div>
                        <div>
                          <h6>Blogs</h6>
                          <ul style={{ padding: "0" }}>
                            {searchdata?.blogs?.length > 0
                              ? searchdata?.blogs?.map((data, index) => {
                                return (
                                  <li style={{ cursor: "pointer" }} onClick={() => redirect_url_function(data, "blogs", index)} key={index}>
                                    {data?.title}
                                  </li>
                                );
                              })
                              : "Blog not found"}
                          </ul>
                        </div>
                        <div>
                          <h6>Puja Subscription</h6>
                          <ul style={{ padding: "0" }}>
                            {searchdata?.pujaSubscription?.length > 0
                              ? searchdata?.pujaSubscription?.map((data, index) => {
                                return (
                                  <li style={{ cursor: "pointer" }} onClick={() => redirect_url_function(data, "subscription", index)} key={index}>
                                    {data?.name}
                                  </li>
                                );
                              })
                              : "Blog not found"}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>) : (
                  <Grid xs={6} item>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Select Banner Link_Type</label>
                      <div onClick={handleAlert} style={{ position: "relative", cursor: "pointer", display: 'inline-block', width: '100%' }}>
                        <input
                          disabled
                          className="form-control"
                          type="text" placeholder="Select Banner Link_Type first" >
                          {/* style={{ pointerEvents: "none" }} */}
                        </input>
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            cursor: 'pointer'
                          }}
                        />
                      </div>
                    </div>


                  </Grid>
                )
            }
          </Grid>

          <div
            className={s["form-login-btn"]}
            onClick={() => Category_function()}
          >
            <Custombutton>{pagetype === "Edit" ? "Update" : "submit"}</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default BannerAdd;


// <Grid item xs={6}>
//   <div className="form-group">
//     <label for="exampleInputEmail1">Redirect Url</label>
//     <select className="form-control" name="" id="" onChange={(e) => setredirectUrl(e.target.value)}>
//       <option>select internal redirect page</option>
//       <option value="events">Events</option>
//       <option value="livedarshan">Live Darshan </option>
//       <option value="blog">Blogs</option>
//       <option value="library">Library Media</option>
//       <option value="panchang">Panchang</option>
//     </select>
//   </div>
// </Grid>