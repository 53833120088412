import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

let get_all_list = getBaseUrl() + "admin/featuredMedia";
let add_featureMedia = getBaseUrl() + "admin/featuredMedia";
let update_feature_media = getBaseUrl() + "admin/featuredMedia";
let delete_feature_media = getBaseUrl() + "admin/featuredMedia";


export const deleteFeatureMedia_api = async (id) => {
  let config = {
    method: "delete",
    url: delete_feature_media + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const get_feature_media_list = async () => {
  let config = {
    method: "get",
    url: get_all_list,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };
  try {
    let res = axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return response.error;
  }
};

export const add_feature_media_api = async (data) => {
  let config = {
    method: "post",
    url: add_featureMedia,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return response.error;
  }
};
export const updateFeature_api = async (data, id) => {
  let config = {
    method: "patch",
    url: update_feature_media + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return response.error;
  }
};
