import React from "react";
import AllLiveDarshans from "../components/LiveDarshans/AllLiveDarshans";

export const LiveDarshansList = () => {
  return (
    <div>
      <AllLiveDarshans />
    </div>
  );
};
