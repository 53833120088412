import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const user_delete = getBaseUrl() + "admin/deleteUser";
const user_status = getBaseUrl() + "admin/updateUser";
const user_update = getBaseUrl() + "admin/updateUser";
const users_list = getBaseUrl() + "admin/getAllUsers";
const transaction_list = getBaseUrl() + "admin/getUserTransaction/";
const subscription_transaction_list = getBaseUrl() + "admin/getUserSubscriptionTransaction/";

export const user_update_api = async (data) => {
  let config = {
    method: "patch",
    url: user_update,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const user_status_api = async (data) => {
  let config = {
    method: "put",
    url: user_status,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const user_delete_api = async (data) => {
  let config = {
    method: "DELETE",
    url: user_delete,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllUsers = async (data) => {
  let config = {
    method: "get",
    url: users_list + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    // url: users_list,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const fetchAllTransaction = async (data) => {
  let config = {
    method: "get",
    url: transaction_list + `${data}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const fetchAllSubscriptionTransaction = async (data) => {
  let config = {
    method: "get",
    url: subscription_transaction_list + `${data}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

//  this api is used to uplaod the video url

let transaction_video = getBaseUrl() + `admin/updateUserTransaction`
export const uploadTransactionVideoUrl_api = async (data) => {
  let config = {
    method: "put",
    url: transaction_video,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


let subscription_transaction_video = getBaseUrl() + `admin/updateUserSubscriptionTransactionVideoUrl`
export const uploadVideoUrl_api = async (data) => {
  let config = {
    method: "put",
    url: subscription_transaction_video,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
