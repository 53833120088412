import React from "react";
import EventList from "../components/Event/EventList";

const Event = () => {
  return (
    <div>
      <EventList />
    </div>
  );
};

export default Event;
