import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";

let all_tag_list = getBaseUrl() + "admin/tag";
let add_tag = getBaseUrl() + "admin/tag";
let update_tag = getBaseUrl() + "admin/tag/";
let delete_tag_api = getBaseUrl() + "admin/tag/";

export const get_all_tag_list = async (data) => {
  let config = {
    method: "get",
    url: all_tag_list + `?page=${data?.page}&limit=${data.limit}&search=${data?.search}`,
    // headers: { Authorization: `Bearer : ${Cookies.get("token")}` },
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const get_all_tag_list_dropdown = async (data) => {
  let config = {
    method: "get",
    url: all_tag_list,
    // headers: { Authorization: `Bearer : ${Cookies.get("token")}` },
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const add_tag_api = async (data) => {
  let config = {
    method: "post",
    url: add_tag,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const update_tag_api = async (data, id) => {
  let config = {
    method: "patch",
    url: update_tag + `${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteTag_api = async (id) => {
  let config = {
    method: "DELETE",
    url: delete_tag_api + `${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
