import React, { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import s from "./sidebar.module.css";
import { FiUsers } from "react-icons/fi";
import { MdOutlineArticle, MdEvent } from "react-icons/md";
import { RiCommunityLine } from "react-icons/ri";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { SiOpenbadges } from "react-icons/si";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { PiTagBold } from "react-icons/pi";
import { PiFlagBannerBold } from "react-icons/pi";
import { MdLiveTv } from "react-icons/md";
import { IoLibraryOutline } from "react-icons/io5";
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { AiOutlineDatabase } from "react-icons/ai";

import logo from "../../assets/icons/digibhakti-icon-new.png";
import { useNavigate } from "react-router-dom";
const Sidebar = ({ open }) => {
  const navigate = useNavigate();
  const [menuItems, setmenuItems] = useState([
    {
      title: " Dashboard",
      icons: <RxDashboard size={20} />,
      navigate: "dashboard",
      show: false,
      subItems: [],
    },

    {
      title: " Users",
      icons: <FiUsers size={20} />,
      navigate: "users",
      show: false,
      subItems: [],
    },
    {
      title: "Live Darshan",
      icons: <MdLiveTv size={20} />,
      navigate: "live-darshans",
      show: false,
      subItems: [],
    },
    {
      title: "Library media",
      icons: <IoLibraryOutline size={20} />,
      navigate: "library-media",
      show: false,
      subItems: [],
    },
    {
      title: "Media Category",
      icons: <MdEvent size={20} />,
      navigate: "category-list",
      show: false,
      subItems: [],
    },
    {
      title: "Banner",
      icons: <PiFlagBannerBold size={20} />,
      navigate: "banner-list",
      show: false,
      subItems: [],
    },
    {
      title: "Feature Media",
      icons: <MdOutlineFeaturedPlayList size={20} />,
      navigate: "feature-list",
      show: false,
      suItems: [],
    },
    {
      title: "Tags",
      icons: <PiTagBold size={20} />,
      navigate: "tag-list",
      show: false,
      subItems: [],
    },
    {
      title: "App Data",
      icons: <AiOutlineDatabase size={20} />,
      navigate: "app-data",
      show: false,
      subItems: [],
    },
    // {
    //   title: "Testimonial",
    //   icons: <MdEvent size={20} />,
    //   navigate: "testimonial-list",
    //   show: false,
    //   subItems: [],
    // },
    {
      title: "Blogs",
      icons: <MdEvent size={20} />,
      navigate: "blog-list",
      show: false,
      subItems: [],
    },
    {
      title: "Puja",
      icons: <MdEvent size={20} />,
      navigate: "event-list",
      show: false,
      subItems: [],
    },
    {
      title: "Festivals",
      icons: <MdEvent size={20} />,
      navigate: "holiday-list",
      show: false,
      subItems: [],
    },
    {
      title: "Reviews",
      icons: <MdEvent size={20} />,
      navigate: "review-list",
      show: false,
      subItems: [],
    },
    {
      title: "Certificate",
      icons: <MdEvent size={20} />,
      navigate: "upload-certificate",
      show: false,
      subItems: [],
    },
    {
      title: "Subscription",
      icons: <MdEvent size={20} />,
      navigate: "subscription-list",
      show: false,
      subItems: [],
    },
    {
      title: "Transaction",
      icons: <MdEvent size={20} />,
      navigate: "admin-transaction",
      show: false,
      subItems: [],
    },
    {
      title: "Subscription Transaction",
      icons: <MdEvent size={20} />,
      navigate: "subscription-transaction",
      show: false,
      subItems: [],
    },
  ]);
  return (
    <>
      <section className={s["sidebar"]}>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className={`${s["collapsed-logo"]} ${open ? `${s["logo-section"]}` : `${s["logo-section-hide"]}`
            }`}
        >
          <img
            src={logo}
            alt="logo"
            draggable="false"
            style={{ width: "49%" }}
          />
        </div>
        {menuItems.map((data, i) => (
          <div className={s["sidebar-content"]}>
            <div
              className={s["sidebar-item"]}
              onClick={() => navigate(`/${data?.navigate}`)}
            >
              <div className="sidebaricons">{data.icons}</div>
              <div
                className={
                  open ? `${s["sidebar-title"]}` : `${s["sidebar-title-hide"]}`
                }
              >
                {data.title}
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default Sidebar;
