import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";

const blog_list = getBaseUrl() + "admin/blogs";
const delete_blog = getBaseUrl() + "admin/blog";
const add_blog = getBaseUrl() + "admin/blog";
const update_blog = getBaseUrl() + "admin/blog";

export const get_blog_api = async () => {
  let config = {
    method: "get",
    url: blog_list,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteBlog_api = async (data) => {
  let config = {
    method: "delete",
    url: delete_blog + `/${data}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const add_blog_api = async (data) => {
  let config = {
    method: "post",
    url: add_blog,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const updateBlog_api = async (data) => {
  let config = {
    method: "patch",
    url: update_blog,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
