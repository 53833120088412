import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";


let get_admin_transaction = getBaseUrl() + "admin/getAllTransaction"



export const getadiminTransaction_api = async (data) => {
    let config = {
        method: "get",
        url: get_admin_transaction + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` }
        // data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};