// import React, { useState, useEffect } from "react";
// import s from "./appdata.module.css";
// import { BiArrowBack } from "react-icons/bi";
// import { useNavigate } from "react-router-dom";
// import Custombutton from "../../Common/Custombutton";
// import { notificationHandler } from "../../utils/Notification";
// import { useLocation } from "react-router-dom";
// import { Card, Grid } from "@mui/material";
// import RichTextEditor from "react-rte-17";
// import { get_all_appdata_list, update_appdata_api } from "../api/appdata";
// import Loder from "../../Loder/Loder";
// import { updateLanguage_api } from "../api/librarymedia";

// const AppDataEdit = (props) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [aboutUs, setAboutUs] = useState(RichTextEditor.createEmptyValue());
//   const [termsOfUse, setTermOfUse] = useState(
//     RichTextEditor.createEmptyValue()
//   );
//   const [privacy, setPrivacy] = useState(RichTextEditor.createEmptyValue());
//   const [version, setversion] = useState(RichTextEditor.createEmptyValue());
//   const [contact, setContact] = useState(RichTextEditor.createEmptyValue());
//   const [contentPolicy, setcontentPolicy] = useState(RichTextEditor.createEmptyValue());
//   const [isLoading, setisLoading] = useState(false);
//   const [pageCount, setpageCount] = useState(1);
//   useEffect(() => {
//     getAppDataFunc();
//   }, []);

//   const getAppDataFunc = async () => {
//     setisLoading(true);
//     let temp = {
//       page: pageCount,
//       limit: 8,
//     };
//     try {
//       let res = await get_all_appdata_list(temp);
//       if (res?.data?.status) {
//         res?.data?.data?.appData.map((data) => {
//           if (data?.key == "about_us") {
//             const initialEditorState = RichTextEditor?.createValueFromString(
//               data?.value,
//               "html"
//             );
//             setAboutUs(initialEditorState);
//           }
//           if (data?.key == "terms_of_use") {
//             const initialEditorState = RichTextEditor?.createValueFromString(
//               data?.value,
//               "html"
//             );
//             setTermOfUse(initialEditorState);
//           }
//           if (data?.key == "privacy_&_policy") {
//             const initialEditorState = RichTextEditor?.createValueFromString(
//               data?.value,
//               "html"
//             );
//             setPrivacy(initialEditorState);
//           }
//           if (data?.key == "content_policy") {
//             const initialEditorState = RichTextEditor?.createValueFromString(
//               data?.value,
//               "html"
//             );
//             setContact(initialEditorState);
//           }
//           if (data?.key == "version") {
//             const initialEditorState = RichTextEditor?.createValueFromString(
//               data?.value,
//               "html"
//             );
//             setversion(initialEditorState);
//           }
//         });
//         setisLoading(false);
//       } else {
//         console.log("false Status!");
//         setisLoading(false);
//       }
//     } catch (error) {
//       console.log(error);
//       setisLoading(false);
//     }
//   };

//   const create_user = async () => {
//     const fd = new FormData();
//     fd.append("terms_of_use", JSON.stringify({ english: termsOfUse.toString("html"), }));
//     try {
//       let res = await update_appdata_api(fd, location?.state?.data?._id);
//       if (res.data.status) {
//         getAppDataFunc();
//         notificationHandler({ type: "success", msg: res.data.message });
//       } else {
//         notificationHandler({ type: "success", msg: res.data.message });
//       }
//     } catch (error) {
//       notificationHandler({ type: "danger", msg: error.message });
//       console.log(error);
//     }
//   };
//   return (
//     <>
//       {!isLoading && (
//         <div className="">
//           <Card className={s["admin_container"]}>
//             <Grid
//               container
//               rowSpacing={1}
//               columnSpacing={{ xs: 1, sm: 2, md: 3 }}
//             >
//               <div className={s["grid_design"]}>
//                 <Grid item xs={12}>
//                   <div className="form-group">
//                     <label for="exampleInputEmail1">About_Us</label>
//                     <RichTextEditor
//                       value={aboutUs}
//                       className="employment_jobdescribee"
//                     />
//                   </div>
//                 </Grid>
//                 <div
//                   className={s["form-login-btn"]}
//                   onClick={() => create_user()}
//                 >
//                   <Custombutton>Submit</Custombutton>
//                 </div>
//               </div>
//               <div className={s["grid_design"]}>
//                 <Grid item xs={12}>
//                   <div className="form-group">
//                     <label for="exampleInputEmail1">Terms_of_Use</label>
//                     <RichTextEditor
//                       value={termsOfUse}
//                       onChange={(e) => setTermOfUse(e.target.value)}
//                       className="employment_jobdescribee"
//                     />
//                   </div>
//                 </Grid>
//                 <div
//                   className={s["form-login-btn"]}
//                   onClick={() => create_user()}
//                 >
//                   <Custombutton>Submit</Custombutton>
//                 </div>
//               </div>
//               <div className={s["grid_design"]}>
//                 <Grid item xs={12}>
//                   <div className="form-group">
//                     <label for="exampleInputEmail1">Privacy_&_Policy</label>
//                     <RichTextEditor
//                       value={privacy}
//                       className="employment_jobdescribee"
//                     />
//                   </div>
//                 </Grid>
//                 <div
//                   className={s["form-login-btn"]}
//                   onClick={() => create_user()}
//                 >
//                   <Custombutton>Submit</Custombutton>
//                 </div>
//               </div>
//               <div className={s["grid_design"]}>
//                 <Grid item xs={12}>
//                   <div className="form-group">
//                     <label for="exampleInputEmail1">Contact_Policy</label>
//                     <RichTextEditor
//                       value={contact}
//                       className="employment_jobdescribee"
//                     />
//                   </div>
//                 </Grid>
//                 <div
//                   className={s["form-login-btn"]}
//                   onClick={() => create_user()}
//                 >
//                   <Custombutton>Submit</Custombutton>
//                 </div>
//               </div>
//             </Grid>
//           </Card>
//         </div>
//       )}

//       <Loder loading={isLoading} />
//     </>
//   );
// };

// export default AppDataEdit;




import React, { useState, useEffect } from "react";
import s from "./appdata.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import RichTextEditor from "react-rte-17";
import { get_all_appdata_list, update_appdata_api } from "../api/appdata";
import Loder from "../../Loder/Loder";
import { updateLanguage_api } from "../api/librarymedia";

const AppDataEdit = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [aboutUs, setAboutUs] = useState(RichTextEditor.createEmptyValue());
  const [termsOfUse, setTermOfUse] = useState(RichTextEditor.createEmptyValue());
  const [privacy, setPrivacy] = useState(RichTextEditor.createEmptyValue());
  const [contact, setContact] = useState(RichTextEditor.createEmptyValue());
  const [contentPolicy, setContentPolicy] = useState(RichTextEditor.createEmptyValue());
  const [version, setVersion] = useState(RichTextEditor.createEmptyValue());
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getAppDataFunc();
  }, []);

  const getAppDataFunc = async () => {
    setIsLoading(true);
    let temp = {
      page: pageCount,
      limit: 8,
    };
    try {
      let res = await get_all_appdata_list(temp);
      if (res?.data?.status) {
        res?.data?.data?.appData.forEach((data) => {
          if (data?.key === "about_us") {
            const initialEditorState = RichTextEditor?.createValueFromString(
              data?.value,
              "html"
            );
            setAboutUs(initialEditorState);
          }
          if (data?.key === "terms_of_use") {
            const initialEditorState = RichTextEditor?.createValueFromString(
              data?.value,
              "html"
            );
            setTermOfUse(initialEditorState);
          }
          if (data?.key === "privacy_&_policy") {
            const initialEditorState = RichTextEditor?.createValueFromString(
              data?.value,
              "html"
            );
            setPrivacy(initialEditorState);
          }
          if (data?.key === "content_policy") {
            const initialEditorState = RichTextEditor?.createValueFromString(
              data?.value,
              "html"
            );
            setContact(initialEditorState);
          }
          if (data?.key === "version") {
            const initialEditorState = RichTextEditor?.createValueFromString(
              data?.value,
              "html"
            );
            setVersion(initialEditorState);
          }
        });
        setIsLoading(false);
      } else {
        console.log("false Status!");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const create_user = async (field) => {
    let body = {};

    switch (field) {
      case "about_us":
        body = {
          id: "65e83a2f290d755fbd2d5373",
          key: "about_us",
          value: aboutUs.toString("html")
        };
        break;
      case "terms_of_use":
        body = {
          id: "65e83a3b290d755fbd2d5376",
          key: "terms_of_use",
          value: termsOfUse.toString("html")
        };
        break;
      case "privacy_&_policy":
        body = {
          id: "65eedb0a369825c5b671edf1",
          key: "privacy_&_policy",
          value: privacy.toString("html")
        };
        break;
      case "content_policy":
        body = {
          id: "65f189e6d4ed75249b688b52",
          key: "content_policy",
          value: contact.toString("html")
        };
        break;
      case "version":
        body = {
          id: "65f18a1bd4ed75249b688b53",
          key: "version",
          value: version.toString("html")
        };
        break;
      default:
        return;
    }


    try {
      let res = await update_appdata_api(body);
      if (res.data.status) {
        getAppDataFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  return (
    <>
      {!isLoading && (
        <div className="">
          <Card className={s["admin_container"]}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <div className={s["grid_design"]}>
                <Grid item xs={12}>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">About_Us</label>
                    <RichTextEditor
                      value={aboutUs}
                      onChange={(value) => setAboutUs(value)}
                      className="employment_jobdescribee"
                    />
                  </div>
                </Grid>
                <div
                  className={s["form-login-btn"]}
                  onClick={() => create_user("about_us")}
                >
                  <Custombutton>Submit</Custombutton>
                </div>
              </div>
              <div className={s["grid_design"]}>
                <Grid item xs={12}>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Terms_of_Use</label>
                    <RichTextEditor
                      value={termsOfUse}
                      onChange={(value) => setTermOfUse(value)}
                      className="employment_jobdescribee"
                    />
                  </div>
                </Grid>
                <div
                  className={s["form-login-btn"]}
                  onClick={() => create_user("terms_of_use")}
                >
                  <Custombutton>Submit</Custombutton>
                </div>
              </div>
              <div className={s["grid_design"]}>
                <Grid item xs={12}>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Privacy_&_Policy</label>
                    <RichTextEditor
                      value={privacy}
                      onChange={(value) => setPrivacy(value)}
                      className="employment_jobdescribee"
                    />
                  </div>
                </Grid>
                <div
                  className={s["form-login-btn"]}
                  onClick={() => create_user("privacy_&_policy")}
                >
                  <Custombutton>Submit</Custombutton>
                </div>
              </div>
              <div className={s["grid_design"]}>
                <Grid item xs={12}>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Content_Policy</label>
                    <RichTextEditor
                      value={contact}
                      onChange={(value) => setContact(value)}
                      className="employment_jobdescribee"
                    />
                  </div>
                </Grid>
                <div
                  className={s["form-login-btn"]}
                  onClick={() => create_user("content_policy")}
                >
                  <Custombutton>Submit</Custombutton>
                </div>
              </div>
            </Grid>
          </Card>
        </div>
      )}

      <Loder loading={isLoading} />
    </>
  );
};

export default AppDataEdit;


