import React, { useState, useEffect } from "react";
import s from "./category.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addCategory_api, updateCategory_api } from "../api/category";
import Select from "react-select";
import { getLanguages_api } from "../api/librarymedia";
const AddCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setisLoading] = useState(false);
  const [titleEnglish, settitleEnglish] = useState("");
  const [titleHindi, settitleHindi] = useState("");
  const [desc, setdesc] = useState("");
  const [hindiDesc, setHindidesc] = useState("");
  const [mediaArry, setmediaArry] = useState("");
  const [jobArryId, setjobArryId] = useState(null);
  const [file, setfile] = useState(null);
  const pagetype = location.state.pagetype;

  useEffect(() => {
    settitleEnglish(location?.state?.data?.titleEnglish);
    settitleHindi(location?.state?.data?.titleHindi);
    setHindidesc(location?.state?.data?.hindiDesc);
    setdesc(location?.state?.data?.desc);
    const cc = location?.state?.data?.media?.map((data) => {
      return { value: data?._id, label: data?.titleEnglish };
    });
    setjobArryId(cc);
  }, [location]);

  const Category_function = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("titleEnglish", titleEnglish);
      fd.append("titleHindi", titleHindi);
      fd.append("desc", desc);
      fd.append("hindiDesc", hindiDesc);
      fd.append(
        "media",
        jobArryId.map((data) => {
          return data.value;
        })
      );
      fd.append("thumbnail", file);
      try {
        let res = await addCategory_api(fd);
        if (res.data.status) {
          navigate("/category-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("id", location?.state?.data?._id);
      fd.append("titleEnglish", titleEnglish);
      fd.append("titleHindi", titleHindi);
      fd.append("desc", desc);
      fd.append("hindiDesc", hindiDesc);
      fd.append(
        "media",
        jobArryId.map((data) => {
          return data.value;
        })
      );
      fd.append("thumbnail", file);
      try {
        let res = await updateCategory_api(fd, location?.state?.data?._id);
        if (res.data.status) {
          navigate("/category-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  const handleChange = (selected) => {
    setjobArryId(selected);
  };

  useEffect(() => {
    fetchallLibraryMediaFunc();
  }, []);

  async function fetchallLibraryMediaFunc() {
    setisLoading(true);
    try {
      const temp = {
        page: "",
        limit: "",
        search: "",
      };
      let res = await getLanguages_api(temp);
      if (res.data.status) {
        let cc = res?.data?.data.libraryMedia.map((item) => {
          return { value: item._id, label: item.titleEnglish };
        });
        setmediaArry(cc);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title English</label>
                <input
                  type="text"
                  className="form-control"
                  name="titleEnglish"
                  value={titleEnglish}
                  onChange={(e) => settitleEnglish(e.target.value)}
                  placeholder="Title English"
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title Hindi</label>
                <input
                  type="text"
                  className="form-control"
                  name="titleHindi"
                  value={titleHindi}
                  onChange={(e) => settitleHindi(e.target.value)}
                  placeholder="Title Hindi"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Media</label>
                <Select isMulti options={mediaArry} onChange={handleChange} value={jobArryId} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="Description"
                  value={desc}
                  onChange={(e) => setdesc(e.target.value)}
                  placeholder="Description"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1"> Hindi Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="Description"
                  value={hindiDesc}
                  onChange={(e) => setHindidesc(e.target.value)}
                  placeholder="hindi Description"
                />
              </div>
            </Grid>

          </Grid>

          <div className={s["form-login-btn"]} onClick={() => Category_function()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddCategory;
