import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./FeatureMedia.module.css";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Pagination,
} from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { deleteFeatureMedia_api, get_feature_media_list } from "../api/featureMedia";
import { getBaseUrl2 } from "../../utils";

const FeatureMediaList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allMedia, setallMedia] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [search, setsearch] = useState("");
  const [pageLength, setpageLength] = useState();
  useEffect(() => {
    fetchallFeatureMediaFunc();
  }, [pageCount, search]);

  console.log(allMedia, "all media datas");

  const fetchallFeatureMediaFunc = async () => {
    setisLoading(true);
    let temp = {
      page: pageCount,
      limit: 8,
      search: search?.trim(),
    };
    try {
      let res = await get_feature_media_list(temp);
      if (res.data.status) {
        setallMedia(res?.data?.data.featuredMedia);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("false Status!");
      }
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };

  async function deleteFeatureMediaFunc() {
    setisLoading(true);
    try {
      let res = await deleteFeatureMedia_api(deleteId);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletePopup(false);
        fetchallFeatureMediaFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));
  return (
    <>
      <div className="">
        <div className="beat_heading">
          <div className={s["user-list-heading"]}>
            <div className="user-list-title">
              <h3>Feature Media List</h3>
            </div>
            {/* <div className={s["user-list-search"]}>
              <div className={s["search-box"]}>
                <span style={{ paddingRight: "0.5rem" }}>
                  <BiSearch size={23} />
                </span>
                <input
                  type="text"
                  spellCheck="false"
                  onChange={(e) => {
                    setpageCount(1);
                    setsearch(e.target.value);
                  }}
                  placeholder="Search by name..."
                />
              </div>
              <div className={s["filter-btn"]}>
                <span style={{ paddingRight: "2px" }}>
                  <BiFilter size={20} />
                </span>
                Filter
              </div>
            </div> */}
          </div>
          <div className="beat_left">
            <div
              className={s["title"]}
              onClick={() =>
                navigate("/add-feature-media", {
                  state: {
                    pagetype: "Add",
                  },
                })
              }
            >
              <IoMdAdd /> Feature Media
            </div>
          </div>
        </div>
        <div className="beat_table">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Thumbnail</StyledTableCell>
                <StyledTableCell align="center">English Title</StyledTableCell>
                <StyledTableCell align="center">Hindi Title</StyledTableCell>
                <StyledTableCell align="center">Tags</StyledTableCell>
                <StyledTableCell align="center">Priority</StyledTableCell>
                {/* <StyledTableCell align="center">Status</StyledTableCell> */}
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>

            {/* <div style={{ margin: "0.5rem 0" }}></div> */}

            <TableBody>
              {allMedia?.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    <img
                      src={getBaseUrl2() + row?.thumbnail?.url}
                      style={{ width: "50px", height: "50px" }}
                      alt="thumbnail"
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row?.titleEnglish} </StyledTableCell>
                  <StyledTableCell>{row?.titleHindi} </StyledTableCell>
                  <StyledTableCell>{row?.tag} </StyledTableCell>
                  <StyledTableCell>{row?.priority} </StyledTableCell>
                  {/* <StyledTableCell align="center">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => language_status(row)}
                      className={`${
                        row.status === true ? s.active_admin : s.inactive_admin
                      }`}
                    >
                      {row.status === true ? "Active" : "Inactive"}
                    </div>
                  </StyledTableCell> */}
                  <StyledTableCell align="center">
                    <CiEdit
                      onClick={() =>
                        navigate("/add-feature-media", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                          },
                        })
                      }
                      style={{
                        fontSize: "1rem",
                        color: "var(--clr-primary)",
                        marginRight: "0.5rem",
                        cursor: "pointer",
                      }}
                    />
                    <MdDelete
                      onClick={() => {
                        setdeletePopup(true);
                        setcurrentGroup(row);
                        setdeleteId(row._id);
                      }}
                      style={{
                        fontSize: "1rem",
                        color: "var(--clr-primary)",
                        cursor: "pointer",
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {allMedia?.length <= 0 && <DataNotFound />}
          {allMedia?.length > 0 && (
            <div className={s["pagination"]}>
              <Pagination
                count={pageLength}
                size="large"
                style={{ color: "#D21903" }}
                onChange={(e, value) => setpageCount(value)}
                page={pageCount}
              />
            </div>
          )}
        </div>

        <Dialog
          open={deletePopup}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="true"
          onClose={() => setdeletePopup(false)}
        >
          <DialogTitle className={s.dialog_title}>
            <div>Do you want to delete {currentGroup.titleEnglish}?</div>
          </DialogTitle>
          <DialogContent className={s.cardpopup_content}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <div
                className={s.employee_gl_popup}
                onClick={() => setdeletePopup(false)}
              >
                Cancel
              </div>
              <div className={s.employee_gl_popup_del} onClick={() => deleteFeatureMediaFunc()}>Delete</div>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
        <Loder loading={isLoading} />
      </div>
    </>
  );
};

export default FeatureMediaList;
