import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const add_live_darshan = getBaseUrl() + "admin/live_darshan";
const updatelive_darshan = getBaseUrl() + "admin/live_darshan";
const delete_live_darshan = getBaseUrl() + "admin/live_darshan";
const live_darshans = getBaseUrl() + "admin/live_darshans";

export const update_skill_api = async (id, data) => {
  let config = {
    method: "PATCH",
    url: updatelive_darshan + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const add_skill_api = async (data) => {
  let config = {
    method: "post",
    url: add_live_darshan,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteSkill_api = async (id) => {
  let config = {
    method: "delete",
    url: delete_live_darshan + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllSkills = async (data) => {
  let config = {
    method: "get",
    url: live_darshans + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
