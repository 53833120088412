import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const get_reviews = getBaseUrl() + "admin/getReviews";
const status_update_review = getBaseUrl() + "admin/updateReview";
const deleteTestimonial = getBaseUrl() + "admin/deleteTestimonial";
const addTestimonial = getBaseUrl() + "admin/addTestimonial";
const updateTestimonial = getBaseUrl() + "admin/updateTestimonial";

export const updateTestimonial_api = async (data) => {
  let config = {
    method: "patch",
    url: updateTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addTestimonial_api = async (data) => {
  let config = {
    method: "post",
    url: addTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteTestimonial_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const review_status_detail = async (data) => {
  let config = {
    method: "put",
    url: status_update_review + `/${data.id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: { isApproved: data.isApproved },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getReview_api = async (data) => {
  let config = {
    method: "post",
    url: get_reviews,
    // + `?page=${data.page}&limit=${data.limit}&search=${data.search}`
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
