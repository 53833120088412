import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const addlibrary_media = getBaseUrl() + "admin/library_media";
const delete_library_media = getBaseUrl() + "admin/library_media";
const all_library_media = getBaseUrl() + "admin/all_library_media";
const updatelibrary_media = getBaseUrl() + "admin/library_media";

export const addLanguage_api = async (data) => {
  let config = {
    method: "POST",
    url: addlibrary_media,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const updateLanguage_api = async (data, id) => {
  let config = {
    method: "PATCH",
    url: updatelibrary_media + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteLanguage_api = async (id) => {
  let config = {
    method: "DELETE",
    url: delete_library_media + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getLanguages_api = async (data) => {
  let config = {
    method: "get",
    // url: getLanguages + `,
    url: all_library_media + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


const library_media_by_id = getBaseUrl() + "admin/library_media";

export const getLibraryMediaById_api = async (data) => {
  let config = {
    method: "get",
    // url: getLanguages + `,
    url: library_media_by_id + `/${data}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
