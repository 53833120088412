import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const getBanners = getBaseUrl() + "admin/banners";
const statusUpdate_banner = getBaseUrl() + "admin/updateBanner";
const deleteBanner = getBaseUrl() + "admin/banner";
const addBanner = getBaseUrl() + "admin/banner";
const updateBanner = getBaseUrl() + "admin/banner";

const globalsearch = getBaseUrl() + "admin/searchData";

// export const globalsearch_api = async (data) => { 
//   try {
//     let res = await fetch(globalsearch, {
//       method: "post",
//       headers: {
//         "Content-Type": "application/json",
//         // Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });
//     let response = await res.json();
//     if (response.status) {
//       return response;
//     }
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };

export const updateBanner_api = async (data) => {
  let config = {
    method: "patch",
    url: updateBanner,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addBanner_api = async (data) => {
  let config = {
    method: "post",
    url: addBanner,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteBanner_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteBanner + `/${data}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const statusUpdate_banner_api = async (data) => {
  let config = {
    method: "patch",
    url: statusUpdate_banner,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getBanners_api = async (data) => {
  let config = {
    method: "get",
    url:
      getBanners +
      `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/json",
    },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const Globalsearch_api = async (data) => {
  let config = {
    method: "post",
    url:
      globalsearch,
    headers: {
      // Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



