import React, { useState, useEffect } from "react";
import s from "./language.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import RichTextEditor from "react-rte-17";
import { get_all_tag_list, get_all_tag_list_dropdown } from "../api/tag";
import MultipleSelect from "./MultipleSelect";
import { addLanguage_api, updateLanguage_api } from "../api/librarymedia";

const AddLibraryMedia = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [titleEnglish, settitleEnglish] = useState("");
  const [titleHindi, settitleHindi] = useState("");
  const [descEnglish, setdescEnglish] = useState("");
  const [descHindi, setdescHindi] = useState("");
  const [url, seturl] = useState("");
  const [thumbnail, setthumbnail] = useState("");
  const [rte, setrte] = useState(RichTextEditor.createEmptyValue());
  const [rte2, setrte2] = useState(RichTextEditor.createEmptyValue());

  // console.log(rte2, "--------------------rte2 value==========================================================================")

  const [tags, settags] = useState([]);

  const [allTag, setallTag] = useState([]); // tag list data

  const pagetype = location.state.pagetype;

  useEffect(() => {
    fetchallTagFunc();
  }, []);

  useEffect(() => {
    if (pagetype == "Edit") {
      settitleEnglish(location?.state?.data?.titleEnglish);
      settitleHindi(location?.state?.data?.titleHindi);
      setdescEnglish(location?.state?.data?.descEnglish);
      setdescHindi(location?.state?.data?.descHindi);
      seturl(location?.state?.data?.url);
      setthumbnail(location?.state?.data?.thumbnail?.url);
      const initialEditorState = RichTextEditor.createValueFromString(
        location?.state?.data?.lyrics?.hindi,
        "html"
      );
      const initialEditorState2 = RichTextEditor.createValueFromString(
        location?.state?.data?.lyrics?.english,
        "html"
      );
      setrte(initialEditorState);
      setrte2(initialEditorState2);
    }

  }, [location]);

  const onChange1 = (value) => {
    setrte(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };
  const onChange2 = (value) => {
    console.log(value, "value ===========================================================================================")
    setrte2(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  const create_user = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("titleEnglish", titleEnglish);
      fd.append("titleHindi", titleHindi);
      fd.append("descEnglish", descEnglish);
      fd.append("descHindi", descHindi);
      fd.append("url", url);
      fd.append("mediaType", "audio");
      fd.append(
        "lyrics",
        JSON.stringify({
          hindi: rte.toString("html"),
          english: rte2.toString("html"),
        })
      );
      fd.append("tags", tags);
      fd.append("thumbnail", thumbnail);

      try {
        let res = await addLanguage_api(fd);
        if (res.data.status) {
          navigate("/library-media");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("titleEnglish", titleEnglish);
      fd.append("titleHindi", titleHindi);
      fd.append("descEnglish", descEnglish);
      fd.append("descHindi", descHindi);
      fd.append("url", url);
      fd.append("mediaType", "audio");
      fd.append(
        "lyrics",
        JSON.stringify({
          hindi: rte.toString("html"),
          english: rte2.toString("html"),
        })
      );
      fd.append("tags", tags);
      fd.append("thumbnail", thumbnail);
      try {
        let res = await updateLanguage_api(fd, location?.state?.data?._id);
        if (res.data.status) {
          navigate("/library-media");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  const fetchallTagFunc = async () => {
    console.log("This is a testing");
    // let temp = {
    //   page: pageCount,
    //   limit: 8,
    //   search: search?.trim(),
    // };
    try {
      let res = await get_all_tag_list_dropdown();
      if (res.data.status) {
        setallTag(res?.data?.data?.tags);
      } else {
        console.log("false Status!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title English</label>
                <input
                  type="text"
                  className="form-control"
                  name="titleEnglish"
                  value={titleEnglish}
                  onChange={(e) => settitleEnglish(e.target.value)}
                  placeholder="Title English"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title Hindi</label>
                <input
                  type="text"
                  className="form-control"
                  name="titleHindi"
                  value={titleHindi}
                  onChange={(e) => settitleHindi(e.target.value)}
                  placeholder="Title Hindi"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Desc English</label>
                <input
                  type="text"
                  className="form-control"
                  name="descEnglish"
                  value={descEnglish}
                  onChange={(e) => setdescEnglish(e.target.value)}
                  placeholder="Desc English"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Desc Hindi</label>
                <input
                  type="text"
                  className="form-control"
                  name="descHindi"
                  value={descHindi}
                  onChange={(e) => setdescHindi(e.target.value)}
                  placeholder="Desc Hindi"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">URL</label>
                <input
                  type="text"
                  className="form-control"
                  name="url"
                  value={url}
                  onChange={(e) => seturl(e.target.value)}
                  placeholder="url"
                />
              </div>
            </Grid>
            {/* <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Tags</label>
                <select
                  className="form-control"
                  value={tags.join(" ,")}
                  onChange={(e) => settags([...tags, e.target.value])}
                >
                  <option default>Select Tag</option>
                  {allTag?.map((row, id) => (
                    <option value={row?.id}>{row?.name}</option>
                  ))}
                </select>
              </div>
            </Grid> */}
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Tags</label>
                <MultipleSelect
                  settags={settags} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Thumbnail</label>
                <input
                  type="file"
                  className="form-control"
                  name="thumbnail"
                  // value={thumbnail}
                  onChange={(e) => setthumbnail(e.target.files[0])}
                  placeholder="Thumbnail"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Lyrics (Hindi)</label>
                <RichTextEditor
                  value={rte}
                  className="employment_jobdescribee"
                  onChange={onChange1}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Lyrics (English)</label>
                <RichTextEditor
                  value={rte2}
                  className="employment_jobdescribee"
                  onChange={onChange2}
                />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => create_user()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddLibraryMedia;

// import * as React from 'react';
// import { useTheme } from '@mui/material/styles';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

// export default function MultipleSelect() {
//   const theme = useTheme();
//   const [personName, setPersonName] = React.useState([]);

//   const handleChange = (event) => {
//     const {
//       target: { value },
//     } = event;
//     setPersonName(
//       // On autofill we get a stringified value.
//       typeof value === 'string' ? value.split(',') : value,
//     );
//   };

//   return (
//     <div>
//       <FormControl sx={{ m: 1, width: 300 }}>
//         <InputLabel id="demo-multiple-name-label">Name</InputLabel>
//         <Select
//           labelId="demo-multiple-name-label"
//           id="demo-multiple-name"
//           multiple
//           value={personName}
//           onChange={handleChange}
//           input={<OutlinedInput label="Name" />}
//           MenuProps={MenuProps}
//         >
//           {names.map((name) => (
//             <MenuItem
//               key={name}
//               value={name}
//               style={getStyles(name, personName, theme)}
//             >
//               {name}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </div>
//   );
// }
